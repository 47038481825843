import React from 'react'
import LayoutStyle from "../components/layout"
import SEO from "../components/seo"
import {ArticleImage} from "./article.style"
import BlockContent from '@sanity/block-content-to-react'
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"


const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const Article = ({pageContext}) =>{

  //console.log(pageContext)

  return(
    <LayoutStyle>
      <SEO
        title={pageContext.title}
        description={(pageContext.intro?pageContext.intro:"")}
      />

      {pageContext.image && pageContext.image.asset &&
        <>
          <ArticleImage src={pageContext.image.asset.url + "?w=1200"}/>
          {pageContext.image_text &&
            <small>{pageContext.image_text}</small>
          }
        </>
      }
      <h1>{pageContext.title}</h1>

      {pageContext.intro &&
        <p>
          {pageContext.intro}
        </p>
      }

      <div>
        <BlockContent blocks={pageContext.content} serializers={serializers}/>
      </div>


    </LayoutStyle>
  )
}

export default Article
